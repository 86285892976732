:root {
  --main-bg-color: #7386D5;
  --second-bg-color: #6d7fcc;
  /* --main-bg-color: rgb(0, 0, 80);
  --second-bg-color: rgb(0, 0, 60); */
  /* --main-bg-color: blue;
  --second-bg-color: darkblue; */
}
a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.2s;
}
#sidebar {
  margin-left: -250px;
  min-width: 250px;
    max-width: 250px;
    background: var(--main-bg-color);
    color: #fff;
    transition: all 0.1s;
}
#sidebar.active {
  margin-left: 0px;
}
#sidebar .sidebar-header {
    padding: 1rem 0 0 1rem;
    background: var(--second-bg-color);
}
#sidebar ul.components {
    padding: 0;
    /* border-bottom: 1px solid #47748b; */
}
#sidebar ul p {
    color: #fff;
    padding: 10px;
}
#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a:hover {
    color: var(--main-bg-color);
    background: #fff;
}
#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: var(--second-bg-color);
}
a[data-toggle="collapse"] {
    position: relative;
}
.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: var(--second-bg-color);
}
ul.CTAs {
    padding: 20px;
}
ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}
a.download {
    background: #fff;
    color: var(--main-bg-color);
}
a.article, a.article:hover {
    background: var(--second-fg-color) !important;
    color: #fff !important;
}
#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}
