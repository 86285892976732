.body .code {
  width: 100%;
}
.code .left {
  text-align: right;
  width: 50%;
  vertical-align: middle;
  padding: 0px 8px;
  font-style: italic;
}
.code .left pre {
  color: darkgreen;
}
.code .right {
  text-align: left;
  width: 50%;
  padding: 0px 8px;
  vertical-align: middle;
  background: rgb(240, 240, 240);
}
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0;
  background-color: rgb(240, 240, 240);
  color: black;
  font-family: monospace;
  /* font-size: 95%; */
}
.hljs-comment, .hljs-quote {
  color: #bc9458;
  font-style: italic;
}
.hljs-keyword, .hljs-selector-tag {
  color: #4437ff;
}
.hljs-number, .hljs-regexp, .hljs-string, .hljs-template-variable, .hljs-variable {
  color: #699600;
}
.hljs-subst{
  color:#519f50;
}
.hljs-name,.hljs-tag{
  color:#e8bf6a;
}
.hljs-type{
  color:#da4939;
}
.hljs-attr,.hljs-built_in,.hljs-builtin-name,.hljs-bullet,.hljs-link,.hljs-symbol{
  color:#6d9cbe;
}
.hljs-params{
  color:black;
}
.hljs-attribute{
  color:#cda869;
}
.hljs-meta{
  color:#004600;
}
.hljs-section,.hljs-title{
  color:#b62a00;
}
.hljs-addition{
  background-color:#144212;
  color:#e6e1dc;
  display:inline-block;
  width:100%;
}
.hljs-deletion{
  background-color:#600;
  color:#e6e1dc;
  display:inline-block;
  width:100%;
}
.hljs-selector-class{
  color:#9b703f;
}
.hljs-selector-id{
  color:#8b98ab;
}
.hljs-emphasis{
  font-style:italic;
}
.hljs-strong{
  font-weight:700;
}
.hljs-link{
  text-decoration:underline;
}

