.lefticons {
  height: 18px;
  width: 18px;
  cursor: pointer;
  background-color: transparent;
}
.lefticons:hover {
  color: green;
}
.html {
  width: 95%;
  padding: '5px';
}
.html ul {
  width: 100%;
}
.html img {
  width: 80%;
}
.html a {
  color: darkblue;
  background-color: white;
  padding-left: 0px;
}
.html a:hover {
  color: blue;
}
.html em {
  color: green;
}