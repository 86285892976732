.body pre {
  font-size: 100%;
  font-family: monospace;
}
.body .center {
  text-align: center;
  width: 100%;
  display: inline-block;
}
.body span.reserved {
  height: 16px;
  width: 8px;
  float: right;
}
