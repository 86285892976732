.container { display: flex; flex-wrap: wrap }
.header {
  display: block;
  margin: auto;
  margin-bottom: 0rem;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  color: #333;
}
.followme-txt {
  display: block;
  align-items: center;
  margin-bottom: 0rem;
  text-align: center;
  font-size: 1rem;
  color: lightgrey;
}
.followme {
  display: block;
  align-items: center;
  margin-bottom: 0rem;
  text-align: center;
  font-size: 1.5rem;
  color: #00acee;
}
.followme:hover {
  /* background-color: lightgray; */
  color: darkgreen;
}
.navbar {
  background-color: transparent;
  padding: 0px;
}
.navbar-btn {
  height: 18px;
  width: 18px;
  cursor: pointer;
  background-color: transparent;
}
.navbar-btn:hover {
  color: green;
}