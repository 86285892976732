.footer {
  margin-top: 0;
  margin-bottom: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  opacity:1;
  font-size: 1rem;
  color: black;
}
.footer:hover {
  opacity: 1;
}
.footer hr {
  margin-top: 0;
  margin-bottom: 2px;
}
.footer .left {
  text-align: left;
  padding-left: 2px;
}
.footer .middle {
  text-align: center;
  position: fixed;
  left: 45%;
}
.footer .right {
  float: right;
  text-align: right;
}
.footer .left:hover, .footer .middle .icon:hover, .footer .middle .icon:hover, .footer .middle .email:hover, .footer .right:hover {
  color: blue;
}
