.alert {
    padding: 0;
    background-color: #f44336; /* Red */
    color: white;
    margin-bottom: 5px;
  }
  
  /* The close button */
  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  /* When moving the mouse over the close button */
  .closebtn:hover {
    color: black;
  }
.contactme {
    color: darkblue;
    font-weight: bold;
}
.contactme:hover {
    color: #f44336;
    background-color: lightgoldenrodyellow;
}