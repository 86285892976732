.righticons {
  height: 18px;
  width: 18px;
  float: right;
  cursor: pointer;
  background-color: transparent;
}
.righticons:hover {
  color: green;
}
img.righticons:hover {
  -webkit-filter: invert(1);
  filter: invert(1);
}
