.App {
  display: flex;
  width: 100%;
  align-items: stretch;
  /* perspective: 1500px;   */
}
#content {
  /* position: fixed; */
  height: 100%;
  width: 100%;
  min-height: 100vh;
  /* padding-left: 15rem; */
  padding-bottom: 2rem;
}
pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  margin: 0;
}
h4 {
  margin: 0px;
  font-size: 1.7rem;
}
dl, ol, ul {
  margin-bottom: 0px;
}