.intro {
  padding-left: 10px;
}
.intro a {
  color: blue;
}
.intro a:hover {
  color: darkgreen;
}
.intro table {
  width: 98%;
}
.intro table tr {
  border: 1px solid black;
}
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}